import { useState } from "react";
import axios from "axios";

function Login({ setUser }) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message before each submit
    try {
      const res = await axios.post("https://crm.royalmarketingassam.com/api/login", { phone, password });
      setUser(res.data);
    } catch (err) {
      setError("Login failed. Please check your phone and password.");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <center>
        <img src="logo.jpg" alt="Logo" style={styles.logo} />
        </center> {/* Add your logo file path */}
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.title}>Royal CRM Login</h2>
          <input
            type="text"
            placeholder="Phone"
            style={styles.input}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            style={styles.input}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p style={styles.error}>{error}</p>}
          <button type="submit" style={styles.button}>
            Login
          </button>
        </form>
        <br />
        <p style={styles.text}>Designed by Star Solutions</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
    padding: '1rem',
  },
  formContainer: {
    width: '100%',
    maxWidth: '400px',
    padding: '2rem',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  logo: {
    width: '100px',
    marginBottom: '1rem',
  },
  title: {
    marginBottom: '1rem',
    fontSize: '1.5rem',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '0.8rem',
    margin: '0.5rem 0',
    borderRadius: '4px',
    border: '1px solid #ddd',
    outline: 'none',
    fontSize: '1rem',
  },
  error: {
    color: 'red',
    fontSize: '0.9rem',
    marginTop: '0.5rem',
  },
  button: {
    width: '100%',
    padding: '0.8rem',
    marginTop: '1rem',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '1rem',
    cursor: 'pointer',
  },
};

export default Login;
