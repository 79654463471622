// src/components/Sidebar.js
import React from "react";
import { Link } from "react-router-dom";

function Sidebar({ onLogout, role, isOpen, toggleSidebar }) {
  return (
    <div>
      {/* Overlay for mobile sidebar */}
      <div
        className={`fixed inset-0 bg-black opacity-50 z-30 ${
          isOpen ? "block" : "hidden"
        } md:hidden`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar content */}
      <div
        className={`fixed top-0 left-0 h-full w-72 bg-blue-500 text-white z-40 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:translate-x-0 md:static md:flex md:flex-col p-6 shadow-lg`}
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold">{role === "admin" ? "Admin" : "User"} Dashboard</h2>
          <button onClick={toggleSidebar} className="md:hidden text-white">
            ✕
          </button>
        </div>

        <nav className="flex flex-col space-y-4">
          {role === "user" && (
            <>
              <Link to="/user/field-visit" className="hover:bg-gray-700 p-3 rounded">
                Field Visit
              </Link>
              <Link to="/user/tele-calling" className="hover:bg-gray-700 p-3 rounded">
                Tele Calling
              </Link>
            </>
          )}
          {role === "admin" && (
            <>
              <Link to="/admin/count-overview" className="hover:bg-gray-700 p-3 rounded">
                Count Overview
              </Link>
              <Link to="/admin/station-overview" className="hover:bg-gray-700 p-3 rounded">
                Station Overview
              </Link>
              <Link to="/admin/user-overview" className="hover:bg-gray-700 p-3 rounded">
                User Overview
              </Link>
              <Link to="/admin/user-management" className="hover:bg-gray-700 p-3 rounded">
                User Management
              </Link>
            </>
          )}
        </nav>

        <button
          onClick={onLogout}
          className="absolute bottom-4 left-4 w-11/12 bg-red-500 p-3 rounded hover:bg-red-600 transition-colors duration-200"
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
