import axios from "axios";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import AdminDashboard from "./components/AdminDashboard";
import Login from "./components/Login";
import UserDashboard from "./components/UserDashboard";
function App() {
  const [user, setUser] = useState(null);

  const refreshToken = async () => {
    try {
      const res = await axios.post("https://crm.royalmarketingassam.com/", { token: user.refreshToken });
      setUser({
        ...user,
        accessToken: res.data.accessToken,
        refreshToken: res.data.refreshToken,
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(
    async (config) => {
      let currentDate = new Date();
      const decodedToken = jwtDecode(user.accessToken);
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        const data = await refreshToken();
        config.headers["authorization"] = "Bearer " + data.accessToken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? (user.isAdmin ? <Navigate to="/admin" /> : <Navigate to="/user" />) : <Login setUser={setUser} />} />
        <Route path="/user/*" element={user && !user.isAdmin ? <UserDashboard phone={user.phone} onLogout={() => setUser(null)} /> : <Navigate to="/" />} />
        <Route path="/admin/*" element={user && user.isAdmin ? <AdminDashboard onLogout={() => setUser(null)} /> : <Navigate to="/" />} />
      </Routes>
  </Router>
  );
}

export default App;