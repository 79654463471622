import React, { useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import crud from '../services/crud'; // Adjust the import path as needed
import { Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const OverviewChart = () => {
  const getLast7Days = () => {
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toISOString().slice(0, 10);
    }).reverse();
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
  const [endDate, setEndDate] = useState(new Date());
  const [dates, setDates] = useState(getLast7Days());
  const [totalLineChartData, setTotalLineChartData] = useState({ labels: dates, datasets: [] });
  const [fieldVisitLineChartData, setFieldVisitLineChartData] = useState({ labels: dates, datasets: [] });
  const [teleCallLineChartData, setTeleCallLineChartData] = useState({ labels: dates, datasets: [] });
  const [fieldVisitPieData, setFieldVisitPieData] = useState({ labels: [], datasets: [] });
  const [teleCallPieData, setTeleCallPieData] = useState({ labels: [], datasets: [] });

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fieldVisits = await crud.getDocuments("Royal_CRM", "fieldVisits");
        const teleCalls = await crud.getDocuments("Royal_CRM", "teleCalls");

        const formatDate = date => date.toISOString().split('T')[0];
        const filteredDates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          filteredDates.push(formatDate(new Date(d)));
        }
        setDates(filteredDates);

        // Prepare data for total count line chart
        const totalFieldVisitsCounts = filteredDates.map(date =>
          fieldVisits.filter(visit => visit.timestamp.startsWith(date)).length
        );
        const totalTeleCallsCounts = filteredDates.map(date =>
          teleCalls.filter(call => call.timestamp.startsWith(date)).length
        );

        setTotalLineChartData({
          labels: filteredDates,
          datasets: [
            {
              label: 'Total Field Visits',
              data: totalFieldVisitsCounts,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderWidth: 2,
              pointRadius: 4,
            },
            {
              label: 'Total Tele Calls',
              data: totalTeleCallsCounts,
              borderColor: 'rgba(153, 102, 255, 1)',
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderWidth: 2,
              pointRadius: 4,
            },
          ],
        });

        // Prepare data for individual employee line charts
        const employeeFieldVisits = {};
        const employeeTeleCalls = {};

        fieldVisits.forEach(visit => {
          const { phone } = visit;
          const date = visit.timestamp.split('T')[0];
          if (!employeeFieldVisits[phone]) {
            employeeFieldVisits[phone] = {};
          }
          employeeFieldVisits[phone][date] = (employeeFieldVisits[phone][date] || 0) + 1;
        });

        teleCalls.forEach(call => {
          const { phone } = call;
          const date = call.timestamp.split('T')[0];
          if (!employeeTeleCalls[phone]) {
            employeeTeleCalls[phone] = {};
          }
          employeeTeleCalls[phone][date] = (employeeTeleCalls[phone][date] || 0) + 1;
        });

        const fieldVisitDatasets = Object.keys(employeeFieldVisits).map(phone => ({
          label: `Employee ${phone} - Field Visits`,
          data: filteredDates.map(date => employeeFieldVisits[phone][date] || 0),
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(),
          borderWidth: 2,
          pointRadius: 4,
        }));

        const teleCallDatasets = Object.keys(employeeTeleCalls).map(phone => ({
          label: `Employee ${phone} - Tele Calls`,
          data: filteredDates.map(date => employeeTeleCalls[phone][date] || 0),
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(),
          borderWidth: 2,
          pointRadius: 4,
        }));

        setFieldVisitLineChartData({
          labels: filteredDates,
          datasets: fieldVisitDatasets,
        });

        setTeleCallLineChartData({
          labels: filteredDates,
          datasets: teleCallDatasets,
        });

        // Prepare pie chart data
        const employeePhones = Object.keys(employeeFieldVisits);
        const fieldVisitCountsByEmployee = employeePhones.map(phone =>
          Object.values(employeeFieldVisits[phone]).reduce((a, b) => a + b, 0)
        );
        const teleCallCountsByEmployee = employeePhones.map(phone =>
          Object.values(employeeTeleCalls[phone]).reduce((a, b) => a + b, 0)
        );
        const backgroundColors = employeePhones.map(() => getRandomColor());

        setFieldVisitPieData({
          labels: employeePhones,
          datasets: [{
            label: 'Total Field Visits by Employee',
            data: fieldVisitCountsByEmployee,
            backgroundColor: backgroundColors,
          }],
        });

        setTeleCallPieData({
          labels: employeePhones,
          datasets: [{
            label: 'Total Tele Calls by Employee',
            data: teleCallCountsByEmployee,
            backgroundColor: backgroundColors,
          }],
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const handleStartDateChange = (newDate) => setStartDate(newDate);
  const handleEndDateChange = (newDate) => setEndDate(newDate);

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top', align: 'center' },
      title: { display: true },
    },
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true },
    },
  };

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', marginTop: '20px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newDate) => handleStartDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newDate) => handleEndDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Grid container spacing={4}>
        {/* Total Count Line Chart */}
        <Grid item xs={12}>
          <Line data={totalLineChartData} options={{ ...lineOptions, plugins: { ...lineOptions.plugins, title: { display: true, text: 'Total Field Visit and Tele Call Counts' } } }} />
        </Grid>

        {/* Individual Employee Line Charts */}
        <Grid item xs={12}>
          <Line data={fieldVisitLineChartData} options={{ ...lineOptions, plugins: { ...lineOptions.plugins, title: { display: true, text: 'Field Visits by Employee' } } }} />
        </Grid>
        <Grid item xs={12}>
          <Line data={teleCallLineChartData} options={{ ...lineOptions, plugins: { ...lineOptions.plugins, title: { display: true, text: 'Tele Calls by Employee' } } }} />
        </Grid>

        {/* Pie Charts */}
        <Grid item xs={6}>
          <Pie data={fieldVisitPieData} options={{ ...pieOptions, plugins: { ...pieOptions.plugins, title: { display: true, text: 'Total Field Visits by Employee' } } }} />
        </Grid>
        <Grid item xs={6}>
          <Pie data={teleCallPieData} options={{ ...pieOptions, plugins: { ...pieOptions.plugins, title: { display: true, text: 'Total Tele Calls by Employee' } } }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewChart;
