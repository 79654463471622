import React, { useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import crud from '../services/crud'; // Adjust the import path as needed
import { Grid, TextField, Autocomplete } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const StationOverview = () => {
  const getLast7Days = () => {
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toISOString().slice(0, 10);
    }).reverse();
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
  const [endDate, setEndDate] = useState(new Date());
  const [dates, setDates] = useState(getLast7Days());
  const [station, setStation] = useState('');
  const [stations, setStations] = useState([]);
  const [lineChartData, setLineChartData] = useState({ labels: dates, datasets: [] });
  const [fieldVisitPieData, setFieldVisitPieData] = useState({ labels: [], datasets: [] });
  const [teleCallPieData, setTeleCallPieData] = useState({ labels: [], datasets: [] });

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

  useEffect(() => {
    const fetchStations = async () => {
      try {
        // Fetch stations from the "stations" collection
        const stationData = await crud.getDocuments("Royal_CRM", "stations");
        setStations(stationData.map(station => station.stationName));
        setStation(stationData[0].stationName);
      } catch (error) {
        console.error("Error fetching stations:", error);
      }
    };

    fetchStations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!station) return; // Only fetch if a station is selected

        const fieldVisits = await crud.getDocuments("Royal_CRM", "fieldVisits");
        const teleCalls = await crud.getDocuments("Royal_CRM", "teleCalls");

        const formatDate = date => date.toISOString().split('T')[0];
        const filteredDates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          filteredDates.push(formatDate(new Date(d)));
        }
        setDates(filteredDates);

        // Filter data based on selected station
        const filteredFieldVisits = fieldVisits.filter(visit => visit.stationName === station);
        const filteredTeleCalls = teleCalls.filter(call => call.stationName === station);

        const fieldVisitCounts = filteredDates.map(date =>
          filteredFieldVisits.filter(visit => visit.timestamp.startsWith(date)).length
        );
        const teleCallCounts = filteredDates.map(date =>
          filteredTeleCalls.filter(call => call.timestamp.startsWith(date)).length
        );

        setLineChartData({
          labels: filteredDates,
          datasets: [
            {
              label: `Field Visits for ${station}`,
              data: fieldVisitCounts,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderWidth: 2,
              pointRadius: 4,
            },
            {
              label: `Tele Calls for ${station}`,
              data: teleCallCounts,
              borderColor: 'rgba(153, 102, 255, 1)',
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderWidth: 2,
              pointRadius: 4,
            },
          ],
        });

        // Prepare pie chart data
        const employeePhones = Array.from(new Set(filteredFieldVisits.map(v => v.phone).concat(filteredTeleCalls.map(c => c.phone))));
        const fieldVisitCountsByEmployee = employeePhones.map(phone =>
          filteredFieldVisits.filter(visit => visit.phone === phone).length
        );
        const teleCallCountsByEmployee = employeePhones.map(phone =>
          filteredTeleCalls.filter(call => call.phone === phone).length
        );
        const backgroundColors = employeePhones.map(() => getRandomColor());

        setFieldVisitPieData({
          labels: employeePhones,
          datasets: [{
            label: 'Field Visits by Employee',
            data: fieldVisitCountsByEmployee,
            backgroundColor: backgroundColors,
          }],
        });

        setTeleCallPieData({
          labels: employeePhones,
          datasets: [{
            label: 'Tele Calls by Employee',
            data: teleCallCountsByEmployee,
            backgroundColor: backgroundColors,
          }],
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, station]);

  const handleStartDateChange = (newDate) => setStartDate(newDate);
  const handleEndDateChange = (newDate) => setEndDate(newDate);
  const handleStationChange = (event, value) => setStation(value);

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top', align: 'center' },
      title: { display: true },
    },
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true },
    },
  };

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', marginTop: '20px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newDate) => handleStartDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newDate) => handleEndDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={stations}
              value={station}
              onChange={handleStationChange}
              renderInput={(params) => <TextField {...params} label="Station Name" />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Grid container spacing={4}>
        {/* Line Chart for Station's Field Visits and Tele Calls */}
        <Grid item xs={12}>
          <Line data={lineChartData} options={{ ...lineOptions, plugins: { ...lineOptions.plugins, title: { display: true, text: `Field Visits and Tele Calls for ${station}` } } }} />
        </Grid>

        {/* Pie Charts */}
        <Grid item xs={6}>
          <Pie data={fieldVisitPieData} options={{ ...pieOptions, plugins: { ...pieOptions.plugins, title: { display: true, text: 'Field Visits by Employee' } } }} />
        </Grid>
        <Grid item xs={6}>
          <Pie data={teleCallPieData} options={{ ...pieOptions, plugins: { ...pieOptions.plugins, title: { display: true, text: 'Tele Calls by Employee' } } }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default StationOverview;
