import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
const crud = require('../services/crud');
function FieldVisit({ phone }) {
  var stationNamesArray = [];
  const [stationName, setStationName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [tradeProducts, setTradeProducts] = useState([]);
  const [options, setOptions] = useState(stationNamesArray);
  useEffect(() => {
    crud.getDocuments("Royal_CRM","stations").then((res) => {
      var data = []
      res.forEach((station) => {
        data.push(station.stationName);
      });
      setOptions(data);
    })
  }, []);
  const tradeProductOptions = ['TILES', 'SANITARY WARE', 'DOORS', 'WATER TANK', 'BATH FITTINGS'];

  const handleStationChange = (event, value) => {
    setStationName(value);
  };

  const handleTradeProductChange = (event, value) => {
    setTradeProducts(value);
  };

  const handleBlur = () => {
    if (stationName && !options.includes(stationName)) {
      Swal.fire({
        title: `The station "${stationName}" does not exist.`,
        text: "Would you like to create it?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, create it!',
      }).then((result) => {
        if (result.isConfirmed) {
          createStation(stationName);
        }
      });
    }
  };

  const createStation = (newStation) => {
    setOptions([...options, newStation]);
    crud.createDocument("Royal_CRM","stations",JSON.stringify({stationName:newStation,createdBy:phone,createdOn:new Date().toISOString()})).then((res) => {
      Swal.fire({
        icon: 'success',
        title: `Station "${newStation}" has been created.`,
        timer: 1500,
        showConfirmButton: false,
      });
    })
  };

  const validateContactNumber = (number) => {
    const isValid = /^[0-9]{10}$/.test(number);
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Contact Number',
        text: 'Contact Number must be exactly 10 digits and contain only numbers.',
      });
    }
    return isValid;
  };

  const handleContactNumberChange = (e) => {
    const value = e.target.value;
    setContactNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!stationName || !businessName || !contactPerson || !contactNumber || tradeProducts.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Form',
        text: 'Please fill out all fields before submitting.',
      });
      return;
    }
    if (!options.includes(stationName)) {
      Swal.fire({
        title: `The station "${stationName}" does not exist.`,
        text: "Would you like to create it?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, create it!',
      }).then((result) => {
        if (result.isConfirmed) {
          createStation(stationName);
        }
      });
      return;
    }

    if (!validateContactNumber(contactNumber)) {
      return;
    }

    const timestamp = new Date().toISOString();
    const formData = {
      stationName,
      businessName,
      contactPerson,
      contactNumber,
      tradeProducts,
      phone,
      timestamp,
    };
    crud.createDocument("Royal_CRM","fieldVisits",JSON.stringify(formData)).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Form Submitted!',
        text: 'Your form has been submitted successfully.',
        timer: 3000,
        showConfirmButton: false,
      })
      document.getElementById("form").reset();
    })  
  };

  return (
    <div style={styles.container}>
      <form id='form' onSubmit={handleSubmit} style={styles.form}>
        <Autocomplete
          freeSolo
          options={options}
          onInputChange={handleStationChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Station Name"
              variant="outlined"
              onBlur={handleBlur}
            />
          )}
          value={stationName.stationName || ''}
        />

        <TextField
          label="Business Name"
          variant="outlined"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />

        <TextField
          label="Contact Person Name"
          variant="outlined"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
        />

        <TextField
          label="Contact Number"
          variant="outlined"
          value={contactNumber}
          inputProps={{ maxLength: 10 }} // Restricts input to 10 characters
          onChange={handleContactNumberChange}
        />

        <Autocomplete
          multiple
          options={tradeProductOptions}
          onChange={handleTradeProductChange}
          renderInput={(params) => (
            <TextField {...params} label="Trade Products" variant="outlined" />
          )}
          value={tradeProducts}
        />

        <button type="submit" style={styles.button}>
          Submit
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    padding: '1rem',
    maxWidth: '500px',
    margin: 'auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  button: {
    padding: '0.8rem',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '1rem',
    cursor: 'pointer',
  },
};

export default FieldVisit;
