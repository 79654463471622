// src/components/AdminDashboard.js
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Navigate, Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import CountOverview from "./countOverview";
import StationOverview from "./StationOverview";
import UserOverview from "./UserOverview";
import UserManagement from "./UserManagement";

function AdminDashboard({ onLogout }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar with toggle control */}
      <Sidebar onLogout={onLogout} role="admin" isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Mobile toggle button (only visible when sidebar is closed) */}
      {!isSidebarOpen && (
        <button
          onClick={toggleSidebar}
          className="md:hidden fixed top-4 left-4 z-50 p-2 bg-gray-800 text-white rounded shadow-lg focus:outline-none"
        >
          <FaBars size={20} />
        </button>
      )}

      {/* Main content */}
      <div className={`flex-grow p-8 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-72' : 'ml-0'}`}>
        <Routes>
          <Route path="/" element={<CountOverview />} />
          <Route path="count-overview" element={<CountOverview />} />
          <Route path="station-overview" element={<StationOverview />} />
          <Route path="/user-overview" element={<UserOverview />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default AdminDashboard;
