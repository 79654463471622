import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Grid, TextField, Button, Typography, Checkbox
} from '@mui/material';
import Swal from 'sweetalert2';
import crud from '../services/crud'; // Adjust the import path as needed

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ phone: '', password: '', isAdmin: false });

  // Fetch users when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await crud.getDocuments("Royal_CRM","Users");
        setUsers(response);
      } catch (err) {
        console.error("Error fetching users:", err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to load users.',
        });
      }
    };

    fetchUsers();
  }, []);

  // Handle input changes for new user form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  // Handle checkbox change for isAdmin
  const handleCheckboxChange = (e) => {
    setNewUser((prev) => ({ ...prev, isAdmin: e.target.checked }));
  };

  // Validate if the user already exists
  const userExists = (phone) => {
    return users.some(user => user.phone === phone);
  };

  // Handle adding new user
  const handleAddUser = async () => {
    if (!newUser.phone || !newUser.password) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Phone and password are required!',
      });
      return;
    }

    if (userExists(newUser.phone)) {
      Swal.fire({
        icon: 'error',
        title: 'User Exists',
        text: 'A user with this phone number already exists.',
      });
      return;
    }

    try {
       await crud.createDocument("Royal_CRM","Users", JSON.stringify(newUser));
      setUsers((prev) => [...prev, newUser]); // Append new user to the list
      setNewUser({ phone: '', password: '', isAdmin: false }); // Reset form
      Swal.fire({
        icon: 'success',
        title: 'User Added',
        text: 'The new user has been added successfully!',
      });
    } catch (err) {
      console.error("Error adding user:", err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add user.',
      });
    }
  };

  // Handle deleting a user
  const handleDeleteUser = async (userId) => {
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this user?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (confirmation.isConfirmed) {
      try {
        await crud.deleteDocument("Royal_CRM","Users", userId);
        setUsers((prev) => prev.filter((user) => user._id !== userId)); // Remove user from list
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'The user has been deleted.',
        });
      } catch (err) {
        console.error("Error deleting user:", err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete user.',
        });
      }
    }
  };

  return (
    <div style={{ maxWidth: '800px', margin: 'auto', marginTop: '20px' }}>
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '20px' }}>User Management</Typography>

      {/* Display Users Table */}
      <TableContainer component={Paper} style={{ maxHeight: 300, overflowY: 'auto' }}>
        <Table aria-label="user table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Password</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Admin Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.password}</TableCell>
                <TableCell>{user.isAdmin ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add New User Form */}
      <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '20px' }}>Add New User</Typography>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone"
            name="phone"
            value={newUser.phone}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Password"
            name="password"
            value={newUser.password}
            onChange={handleInputChange}
            fullWidth
            required
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <label>
            <Checkbox
              checked={newUser.isAdmin}
              onChange={handleCheckboxChange}
              color="primary"
            />
            Is Admin
          </label>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserManagement;
