// src/components/UserDashboard.js
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import FieldVisit from "./FieldVisit";
import TeleCalling from "./TeleCalling";
import Sidebar from "./Sidebar";

function UserDashboard({ phone,onLogout }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar with toggle control */}
      <Sidebar onLogout={onLogout} role="user" isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Mobile toggle button (only visible when sidebar is closed) */}
      {!isSidebarOpen && (
        <button
          onClick={toggleSidebar}
          className="md:hidden fixed top-4 left-4 z-50 p-2 bg-gray-800 text-white rounded shadow-lg focus:outline-none"
        >
          <FaBars size={20} />
        </button>
      )}

      {/* Main content */}
      <div className="ml-0 md:ml-72 p-8 flex-1 transition-all duration-300 ease-in-out">
        <Routes>
          <Route path="/" element={<FieldVisit phone={phone}/>} />
          <Route path="field-visit" element={<FieldVisit phone={phone}/>} />
          <Route path="tele-calling" element={<TeleCalling phone={phone}/>} />
        </Routes>
      </div>
    </div>
  );
}

export default UserDashboard;
