async function createDocument(databaseName, collectionName, data) {
    const response = await fetch(`https://crm.royalmarketingassam.com/api/${databaseName}/${collectionName}/data/${data}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
}

async function getDocuments(databaseName, collectionName, filter = {}) {
    const query = encodeURIComponent(JSON.stringify(filter));
    const response = await fetch(`https://crm.royalmarketingassam.com/api/${databaseName}/${collectionName}?filter=${query}`);
    return response.json();
}



async function deleteDocument(databaseName, collectionName, id) {
    const response = await fetch(`https://crm.royalmarketingassam.com/api/${databaseName}/${collectionName}/${id}`, {
        method: 'DELETE',
    });
    return response.json();
}

// Export all functions
module.exports = {
    createDocument,
    getDocuments,
    deleteDocument
};
