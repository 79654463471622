import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Grid, TextField, Autocomplete, Button, Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import crud from '../services/crud'; // Adjust the import path as needed

const EmployeeDataTables = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
  const [endDate, setEndDate] = useState(new Date());
  const [employee, setEmployee] = useState('All Employees');
  const [employees, setEmployees] = useState([]);
  const [fieldVisitData, setFieldVisitData] = useState([]);
  const [teleCallData, setTeleCallData] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const fieldVisits = await crud.getDocuments("Royal_CRM", "fieldVisits");
        const teleCalls = await crud.getDocuments("Royal_CRM", "teleCalls");

        const employeePhones = Array.from(new Set([...fieldVisits, ...teleCalls].map(record => record.phone)));
        setEmployees(['All Employees', ...employeePhones]);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fieldVisits = await crud.getDocuments("Royal_CRM", "fieldVisits");
        const teleCalls = await crud.getDocuments("Royal_CRM", "teleCalls");

        const filteredFieldVisits = fieldVisits.filter(
          visit => new Date(visit.timestamp) >= startDate && new Date(visit.timestamp) <= endDate
        );
        const filteredTeleCalls = teleCalls.filter(
          call => new Date(call.timestamp) >= startDate && new Date(call.timestamp) <= endDate
        );

        if (employee && employee !== 'All Employees') {
          setFieldVisitData(filteredFieldVisits.filter(visit => visit.phone === employee));
          setTeleCallData(filteredTeleCalls.filter(call => call.phone === employee));
        } else {
          setFieldVisitData(filteredFieldVisits);
          setTeleCallData(filteredTeleCalls);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, employee]);

  const handleStartDateChange = (newDate) => setStartDate(newDate);
  const handleEndDateChange = (newDate) => setEndDate(newDate);
  const handleEmployeeChange = (event, value) => setEmployee(value);

  const downloadCSV = (data, filename) => {
    if (data.length === 0) return;

    const csvRows = [];
    const headers = Object.keys(data[0]).filter(key => key !== '_id'); // Exclude '_id' field
    const renamedHeaders = headers.map(header => {
      switch (header) {
        case 'timestamp': return 'Date';
        case 'phone': return 'Employee Phone';
        case 'stationName': return 'Station Name';
        case 'businessName': return 'Business Name';
        case 'contactPerson': return 'Contact Person';
        case 'contactNumber': return 'Contact Number';
        case 'tradeProducts': return 'Trade Products';
        case 'dealerName': return 'Dealer Name';
        case 'callResponse': return 'Call Response';
        case 'callAgenda': return 'Call Agenda';
        case 'remark': return 'Remark';
        default: return header;
      }
    });
    csvRows.push(renamedHeaders.join(','));

    data.forEach(row => {
      const values = headers.map(header => {
        let value = row[header];
        if (header === 'timestamp') value = new Date(value).toLocaleDateString(); // Convert timestamp to local date string
        if (Array.isArray(value)) value = value.join(', '); // Convert arrays to comma-separated strings
        return JSON.stringify(value || ''); // Ensure the value is stringified properly
      });
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', marginTop: '20px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newDate) => handleStartDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newDate) => handleEndDateChange(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={employees}
              value={employee}
              onChange={handleEmployeeChange}
              renderInput={(params) => <TextField {...params} label="Employee Phone" />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Grid container spacing={4}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>Field Visits</Typography>
          <Button variant="contained" onClick={() => downloadCSV(fieldVisitData, 'Field Visits from '+(new Date(startDate).toLocaleDateString())+' to '+(new Date(endDate).toLocaleDateString())+" of "+employee)}>
            Download Field Visits CSV
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ maxHeight: 300, overflowY: 'auto' }}>
            <Table aria-label="field visit table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                  {employee === 'All Employees' && <TableCell style={{ fontWeight: 'bold' }}>Employee Phone</TableCell>}
                  <TableCell style={{ fontWeight: 'bold' }}>Station Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Business Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Contact Person</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Contact Number</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Trade Products</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldVisitData.map((visit, index) => (
                  <TableRow key={index}>
                    <TableCell>{new Date(visit.timestamp).toLocaleDateString()}</TableCell>
                    {employee === 'All Employees' && <TableCell>{visit.phone}</TableCell>}
                    <TableCell>{visit.stationName}</TableCell>
                    <TableCell>{visit.businessName}</TableCell>
                    <TableCell>{visit.contactPerson}</TableCell>
                    <TableCell>{visit.contactNumber}</TableCell>
                    <TableCell>{visit.tradeProducts ? visit.tradeProducts.join(', ') : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>Tele Calls</Typography>
          <Button variant="contained" onClick={() => downloadCSV(teleCallData, 'Tele Calls from '+(new Date(startDate).toLocaleDateString())+' to '+(new Date(endDate).toLocaleDateString())+" of "+employee)}>
            Download Tele Calls CSV
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ maxHeight: 300, overflowY: 'auto' }}>
            <Table aria-label="tele call table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                  {employee === 'All Employees' && <TableCell style={{ fontWeight: 'bold' }}>Employee Phone</TableCell>}
                  <TableCell style={{ fontWeight: 'bold' }}>Station Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Dealer Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Contact Number</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Call Response</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Call Agenda</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teleCallData.map((call, index) => (
                  <TableRow key={index}>
                    <TableCell>{new Date(call.timestamp).toLocaleDateString()}</TableCell>
                    {employee === 'All Employees' && <TableCell>{call.phone}</TableCell>}
                    <TableCell>{call.stationName}</TableCell>
                    <TableCell>{call.dealerName}</TableCell>
                    <TableCell>{call.contactNumber}</TableCell>
                    <TableCell>{call.callResponse}</TableCell>
                    <TableCell>{call.callAgenda}</TableCell>
                    <TableCell>{call.remark}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeDataTables;
